export default (alignment: string | undefined): string => {
  const align = alignment ? alignment.toLowerCase() : '';
  switch (align) {
    case 'top':
    case 'left':
      return 'flex-start';
    case 'center':
      return 'center';
    case 'bottom':
    case 'right':
      return 'flex-end';
    default:
      return 'flex-start';
  }
};

export const getHorizontalAlignment = (alignmentType: string | undefined): string => {
  const align = alignmentType ? alignmentType.toLowerCase() : '';
  switch (align) {
    case 'reft':
      return 'justify-start';
    case 'right':
      return 'justify-end';
    default:
      return 'justify-center';
  }
};
